import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import '../components/layout.css'
import { 
  Box
} from "@chakra-ui/react"
import Seo from "../Seo"
import SliceZone from '../components/SliceZone/SliceZone'

import PageHero from "../components/PageHero/PageHero"

const WhatWeTradeTemplate = ({ data }) => {

  const document = data.prismicWhatWeTradePage.data;

  return (
    <Layout>
      <Seo title="What We Trade | Plateau Forest Products" />
      <PageHero 
        heroImage={document.hero_image?.localFile}
        alt={document.hero_image.alt}
        pageTitle={document.page_title.text}
        pageSubtitle={document.page_subtitle.text}
      />
        <Box>
          <SliceZone sliceZone={document.body}/>
        </Box>
    </Layout>
  )
}

export const query = graphql`
query WhatWeTradeQuery($uid: String!) 
  {
    prismicWhatWeTradePage(uid: {eq: $uid}) {
        uid
        data {
          page_title {
            text
          }
          page_subtitle {
            text
          }
          hero_image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 80, placeholder: BLURRED)
              }
            }
            alt
          }
          body {
            ... on PrismicWhatWeTradePageDataBodyWhiteCallToActionBlock {
              id
              slice_type
              primary {
                yellow_title {
                  text
                }
                white_headline {
                  text
                }
                button_link {
                  uid
                  url
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyWhatWeTradeBlock {
              id
              slice_type
              items {
                short_description {
                  text
                }
                product_name {
                  text
                }
                product_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyLeftColumn {
              id
              slice_type
              primary {
                right_column_content {
                  raw
                }
                left_column_content {
                  raw
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyTestimonials {
              id
              slice_type
              primary {
                title {
                  text
                }
              }
              items {
                testimonial_text {
                  text
                }
                person_name {
                  text
                }
                company_name {
                  text
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyQuoteBlock {
              id
              slice_type
              primary {
                quote {
                  text
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyMessageBlock {
              id
              slice_type
              primary {
                title {
                  text
                }
                text_content {
                  raw
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyIndustryMembers {
              id
              slice_type
              items {
                industry_logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                  }
                  alt
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyGreenCallToActionBlock {
              id
              slice_type
              primary {
                yellow_title {
                  text
                }
                white_headline {
                  text
                }
                button_link {
                  url
                  uid
                }
                button_label {
                  text
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyContentArea {
              id
              slice_type
              primary {
                white_or_green_block_
                content_title {
                  text
                }
                content {
                  raw
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyCompanyOverviewBlock {
              id
              slice_type
              primary {
                description {
                  text
                }
                block_title {
                  text
                }
              }
            }
            ... on PrismicWhatWeTradePageDataBodyBusinessPromise {
              id
              slice_type
              primary {
                statement {
                  text
                }
                link_label {
                  text
                }
                link {
                  url
                  uid
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                explanation {
                  text
                }
              }
            }
          }
        }
      }
  }
`


export default WhatWeTradeTemplate